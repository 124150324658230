import * as React from 'react'
import loadable from '@loadable/component'

const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const Select = loadable(() => import('/src/components/Form/Select'))

const StepCover = ({ isSubmitting, isSubmitted }) => {
    const coverAmount = [
        `I don't know`,
        '£30,000',
        '£40,000',
        '£50,000',
        '£60,000',
        '£70,000',
        '£80,000',
        '£90,000',
        '£100,000',
        '£150,000',
        '£200,000',
        '£250,000',
        '£300,000',
        '£350,000',
        '£400,000',
        '£450,000',
        '£500,000',
        '£550,000',
        '£600,000',
        '£650,000',
        '£700,000',
        '£750,000',
        '£800,000',
        '£850,000',
        '£900,000',
        '£950,000',
        '£1million +'
    ]
    let coverPeriod = Array.from(Array(73).keys()).slice(5)
    coverPeriod = coverPeriod.map((i) => `${i} years`)
    coverPeriod.unshift(`I don't know`)

    return (
        <FieldSet>
            <FieldSpacer grow={'stretch'}>
                <Select
                    label={'Amount of Cover'}
                    name={'coverAmount'}
                    firstItem={'Please select'}
                    data={coverAmount}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <Select
                    label={'Cover Period'}
                    name={'coverPeriod'}
                    firstItem={'Please select'}
                    data={coverPeriod}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepCover
